import {
  ref,
  readonly,
  computed,
  useContext,
  InjectionKey,
  provide,
  inject
} from '@nuxtjs/composition-api'
import { useLoading } from './ui/useLoading'
import { Suggestions } from '~/types/sugesstion'
import { SearchTerm } from '~/types/search'
import { StorageKeys } from '~/composables/useUniversal'
import {useEvents} from "~/composables/framework/useEvents";

const cursurPosition = ref(-1)
const searchHistoryTerms = ref<SearchTerm[]>([])
const HISTORY_LIMIT = 10
export const useSearchSuggestion = () => {
  const { emit } = useEvents()
  const { $storage } = useContext()
  const {
    app: { $api }
  } = useContext()

  const active = ref(false)
  const keyword = ref('')
  const suggestions = ref<Suggestions | null>(null)
  const loading = useLoading()

  const trimedKeyword = computed(() => keyword.value.trim())

  const ensureSearchHistories = () => {
    if (searchHistoryTerms?.value?.length) return
   getSearchHistories()
  }

  const getSearchHistories = () => {
    const searchTerms = process.client
      ? $storage.getLocalStorage("search_terms")
      : null
    if (searchTerms) {
      searchHistoryTerms.value = searchTerms
    }
    return searchHistoryTerms.value
  }
  const setSearchHistory = (searchTerm: SearchTerm) => {
    const oldSearchTerms = getSearchHistories()
    searchHistoryTerms.value = oldSearchTerms
    if(oldSearchTerms) {
      const index = oldSearchTerms?.findIndex(
        (term) => term.name === searchTerm.name && term.url === searchTerm.url
      )
      // console.log(oldSearchTerms," setSearchHistory called")
      if (index !== 0) {
        if (index !== -1) {
          searchHistoryTerms.value.splice(index, 1)
        }

        searchHistoryTerms.value.unshift(searchTerm)
        if (searchHistoryTerms.value.length > HISTORY_LIMIT) {
          searchHistoryTerms.value = searchHistoryTerms.value.slice(
            0,
            HISTORY_LIMIT
          )
        }
        searchHistoryTerms.value.toString().replace("(","%40")
        searchHistoryTerms.value.toString().replace(")","%41")
        $storage.setLocalStorage(StorageKeys.SearchTerm, searchHistoryTerms.value)
        // console.log(StorageKeys.SearchTerm," setSearchHistory")
      }
    }
  }

  const removeSearchHistory = (searchTerm: SearchTerm) => {
    const oldSearchTerms = getSearchHistories()
    searchHistoryTerms.value = oldSearchTerms?.filter(
      (term) => term.name + term.url !== searchTerm.name + searchTerm.url
    )
    $storage.setLocalStorage(StorageKeys.SearchTerm, searchHistoryTerms.value)
  }
  const showSearchPanel = () => {
    if (active.value === true) return
    active.value = true
  }

  const setCursurPosition = (position: number) => {
    cursurPosition.value = position
  }

  const hideSearchPanel = () => {
    emit("search-input-focus", false);
    if (active.value === false) return
    active.value = false
  }

  const clearKeyword = () => {
    keyword.value = ''
  }

  const suggest = () => {
    return loading.scope(async () => {
      if (trimedKeyword.value) {
        suggestions.value = await $api.productMeili.suggest({
          term: trimedKeyword.value,
        })
      } else {
        suggestions.value = null
      }
    })
  }

  return {
    active: readonly(active),
    cursurPosition: readonly(cursurPosition),
    keyword,
    trimedKeyword,
    loading: loading.value,
    suggestions: readonly(suggestions),
    searchHistoryTerms,
    ensureSearchHistories,
    setSearchHistory,
    removeSearchHistory,
    showSearchPanel,
    hideSearchPanel,
    clearKeyword,
    suggest,
    setCursurPosition
  }
}

// provide searchSuggestions
const SearchSuggestionProviderKey: InjectionKey<
  ReturnType<typeof useSearchSuggestion>

  > = Symbol('Provider:SearchSuggestion')

export const provideSearchSuggestion = () => {
  const result = useSearchSuggestion()
  provide(SearchSuggestionProviderKey, result)
}

// inject searchSuggestions
export const injectSearchSuggestion = () => {
  const result = inject(SearchSuggestionProviderKey)
  if (!result) {
    throw new Error('search suggestions provider not set')
  }
  return result
}
